<template>
  <div v-if="show">
    <el-dialog :visible="dialogshow" title="推广" @close="close" class="dialog-body-paddingTop-10" width="900px">
      <div class="spread-content">
        <div class="spread-left">
					<div v-for="(item, index) in spreadList" :key="index" @click="spreadIndex = index" class="spread-left-item"
						:class="{spreadLeftCurrent: spreadIndex===index}">
						{{item.name}}
					</div>
				</div>
        <div class="spread-right">
					<!-- 链接与小程序码 -->
					<div class="spread-code" v-if="spreadIndex === 0">
						<div class="flexBox">
							<div class="label">小程序路径:</div>
							<div>
								<el-input placeholder="请输入内容" disabled v-model="pageUrl" style="width: 330px;"></el-input>
								<el-button style="margin-left: -5px;position: relative;z-index: 1;"
									v-clipboard:copy="pageUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制
								</el-button>
							</div>
						</div>
						<div class="flexBox">
							<div class="label">短信链接:</div>
							<div>
								<el-input placeholder="请输入内容" disabled v-model="shortLink" style="width: 330px;"></el-input>
								<el-button style="margin-left: -5px;position: relative;z-index: 1;"
									v-clipboard:copy="shortLink" v-clipboard:success="onCopy" v-clipboard:error="onError">复制
								</el-button>
							</div>
						</div>
						<img :src="imgUrl + qrCode" v-if="qrCode" class="qrcode"></img>
						<div style="margin-left: 200px;margin-top: 30px;">
							<el-link type="primary" @click="downloadIamge">下载小程序码</el-link>
						</div>
					</div>
					
					<!-- 小程序分享卡片 -->
					<div class="mini-share-card" v-if="spreadIndex === 1">
						<div class="card-preview">
							<img class="bg" src="https://cdn.dkycn.cn/images/melyshop/mini-share-card-bg.png"></img>
							<div class="card">
								<img class="top" src="https://cdn.dkycn.cn/images/melyshop/mini-share-card-1.png"></img>
								<div class="center">
									<div class="share-text">{{shareDesc || data.Config.title}}</div>
									<img class="share-image" v-if="shareCardImage" :src="imgUrl + shareCardImage"></img>
									<img class="share-image" v-else src="https://cdn.dkycn.cn/images/melyshop/20220225171100.png"></img>
								</div>
								<img class="top" src="https://cdn.dkycn.cn/images/melyshop/mini-share-card-3.png"></img>
							</div>
						</div>
						<div class="share-edit" style="padding-left: 10px;">
							<el-form label-width="80px">
								<el-form-item label="分享卡片:" prop="title">
									<div class="share-image-wraper">
										<el-upload class="share-uploader" :action="imgApi" :show-file-list="false" :on-success="handleShareCardSuccess"
										 :before-upload="beforeShareCardUpload">
											<img v-if="shareCardImage" :src="imgUrl + shareCardImage" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<div class="right">
											<div class="right-tip">建议图片宽高比5:4,宽高比不匹配时,图片将被压缩或拉伸以铺满画面;大小请控制在2M以内</div>
											<div class="right-clear" @click="shareCardImage=''">清空图片</div>
										</div>
									</div>
								</el-form-item>
								<el-form-item label="分享描述:">
									<el-input type="text" v-model="shareDesc" maxlength="25"></el-input>
									<div style="font-size: 12px;color: #999;line-height: 16px;margin-top: 10px;">用户分享页面给微信好友时，将自动使用该描述；若未配置描述，将使用页面标题</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
					
					<!-- 分享海报 -->
					<div class="" v-if="spreadIndex === 2">
						<div>推手分享海报，请前往[分销中心]—[邀请海报]—[推手邀请海报]中管理
              <el-button type="text" style="margin-left:20px" @click="jumpposter">点击前往</el-button>
            </div>
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/promote-shili.png" style="width:535px" alt="">
					</div>
					
				</div>
      </div>
      <div slot="footer" class="dialog-footer" v-if="spreadIndex == 1">
				<el-button @click="close(false,false)">取 消</el-button>
				<el-button type="primary" v-loading="saveLoading" @click="saveMiniShareCardData">保存</el-button>
			</div>
    </el-dialog>
  </div>
</template>

<script>
import config from '@/config/index';
import {
  wxappwxappgenerateurllink
} from "@/api/sv3.0.0";
import apiList from '@/api/other';
import {
  mallFreeVersionConfigInfo
} from "@/api/goods";
import {
  editSave,
  editInit,
  setSharePosterImg,
  malldriverRecruitmentPageShareInfoSave
} from '@/api/pageManage.js';
export default {
  props: {
    visible:{
      type:Boolean,
      default:false,
    },
    data:{
      type:Object,
      default:()=>{
        return {}
      },
    }
  },
  data () {
    return {
      spreadPageId:0,
      imgUrl: config.IMG_BASE,
      goUrls: config.GO_URL,
      imgApi: config.UPLOAD_IMG,
      show:false,
      spreadList: [{
        id: 1,
        name: '链接与小程序码'
      }, {
        id: 2,
        name: '小程序分享卡片'
      }, {
        id: 3,
        name: '分享海报'
      }],
      spreadIndex: 0,

      pageUrl:'',
      shortLink:'',
      qrCode:'',

      shareDesc:'',
      pageTitle:'页面标题',
      shareCardImage:'',

      mallName:'',
      shareImage:'',
      syncChecked: false,
      
      saveLoading: false,
      isIndexPage: false,
      dialogshow:false,
    }
  },
  watch: {
    visible(val){
      this.show = val
      if(val){
        this.spreadIndex = 0;
        this.syncChecked = false;
        this.mallName = window.localStorage.getItem('shopnameTitle');
        this.pageUrl = 'pages/invitation/invitationCard/invitationCard';
        this.shareCardImage = this.data.DriverRecruitmentPageShareCardImgUrl,
        this.shareDesc = this.data.DriverRecruitmentPageShareDescription,
        // console.log(this.data,this.shareCardImage,this.shareDesc)
        this.getShortLink()
				this.getMiniProgramCode();
      }else{
        this.dialogshow = false
      }
    }
  },
  methods:{
    jumpposter(){
      // console.log(this.$route)
      let path = ''
      if(this.$route.name=='driverrecruiting'){
        path = '/distribution/setting/invitationPoster'
      }else{
        path = '/agent/setting/invitationPoster'
      }
      this.$router.push({
        path:path
      })
    },
    /**
     * 获取短信链接
     */
		async getShortLink() {
      let params = {
        Path: 'pages/invitation/invitationCard/invitationCard',
        // query: 'id=' + this.spreadPageId
      }
      let res = await wxappwxappgenerateurllink(params)
      if (res.IsSuccess) {
        this.shortLink = res.Result.url_link
      }
    },
    async getMiniProgramCode() {
      let params = {
        Page: 'pages/invitation/invitationCard/invitationCard',
        Scene: '1',
        IsLimited: false,
        ProgramType: 0,
        noError: true
      }
      let res = await apiList.mallGetQrCode(params);
      
      if (res.ErrorCode === 0) {
        this.dialogshow = true;
        this.qrCode = JSON.parse(res.Result)[0];
      } else {
        this.dialogshow = false;
        if (res.ErrorCode == 1000063) { //未绑定商城小程序
          this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
            this.signLoding = false;
            this.state = 0;// window.localStorage.getItem('mlmzFreePayState');
            if (this.state == 0) {
              this.$confirm('请先绑定商城小程序', '提示', {
                distinguishCancelAndClose: true,
                confirmButtonText: '去绑定',
                cancelButtonText: '关闭'
              }).then(() => {
                this.getMallConfig();
              }).catch(action => {
                this.close();
              })
              return
            }
            window.localStorage.setItem('canEditSetinfos', true);
            window.localStorage.setItem('toPayResultFromPath', this.$route.path);
          }).catch(() => {})
        } else if (res.ErrorCode == 85074) { //小程序未发布
          this.$confirm('商城小程序还未发布上线，推广码生成失败', '', {
            confirmButtonText: '去发布',
            cancelButtonText: '关闭',
          }).then(() => {
            let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
            let url = this.goUrls + headsUrls + '/setting/wxAppManage'
            window.open(url);
            this.close();
          }).catch(() => {
            this.close();
          })
        } else if (res.ErrorCode == 41030) { //page页面不存在
          this.$confirm('获取新页面推广码，请先将小程序版本更新成最新版本', '', {
            confirmButtonText: '去更新',
            cancelButtonText: '关闭',
          }).then(() => {
            let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
            let url = this.goUrls + headsUrls + '/setting/wxAppManage'
            window.open(url);
            this.close();
          }).catch(() => {
            this.close();
          })
        }
      }
    },
    async getMallConfig() {
      let res = await mallFreeVersionConfigInfo()
      this.mallConfigInfo = res.Result;
      window.open(this.mallConfigInfo.WxAppAuthUrl);
      this.close();
    },
    
    async saveMiniShareCardData(){
      
      this.saveLoading = true;
      try{
        
        const params = {
          DriverRecruitmentPageShareCardImgUrl:this.shareCardImage,
          DriverRecruitmentPageShareDescription:this.shareDesc
        }
        
        const res = await malldriverRecruitmentPageShareInfoSave(params);
        
        if (res.IsSuccess) {
          this.$message.success('保存成功')
          this.close(true,true)
        } 
      
      }catch(e){
        console.log(e)
      }finally{
        this.saveLoading = false;
      }
    },
    handleSuccess(res, file) {
      this.shareImage = res[0];
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
    
      if (!isLt2M) {
        this.$message.error('图片大小请控制在2M及以内');
      }
      return isLt2M;
    },
    handleClear(){
      this.shareImage = '';
    },

    handleShareCardSuccess(res, file) {
      this.shareCardImage = res[0];
    },
    beforeShareCardUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
    
      if (!isLt2M) {
        this.$message.error('图片大小请控制在2M及以内');
      }
      return isLt2M;
    },

    handleClick(e){
      this.activeName = e.name
    },
    close(val,refresh){
      // console.log(val)
      this.show = val?true:false
      this.$emit('close',this.show,refresh)
    },
    onCopy() {
      this.$message({
        message: "复制成功！",
        type: 'success'
      });

    },
    onError() {
      this.$message({
        message: "复制失败！",
        type: 'error'
      });
    },
    downloadIamge() {
      var that = this;
      var image = new Image();
      // 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function() {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
    
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png')
    
        // 生成一个a元素
        var a = document.createElement('a')
        // 创建一个单击事件
        var event = new MouseEvent('click')

        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
        a.download = that.qrCode;
        // 将生成的URL设置为a.href属性
        a.href = url
        // 触发a的单击事件
        a.dispatchEvent(event)
      }
    
      image.src = this.imgUrl + this.qrCode;
    
    
    },
  }
}
</script>

<style lang="less" scoped>
  .spread-content {
		display: flex;
		height: 466px;

		.flexBox {
			display: flex;
			margin-bottom: 20px;
		}

		.spread-left {
			flex: 0 0 auto;
			width: 150px;

			.spread-left-item {
				width: 130px;
				height: 36px;
				line-height: 36px;
				padding-left: 10px;
				box-sizing: border-box;
				font-size: 14px;
				cursor: pointer;
			}

			.spreadLeftCurrent {
				background: #ECF5FF;
				color: #409EFF;
			}
		}

		.spread-right {
			flex: 1 1 auto;
			padding-left: 20px;
			border-left: 1px solid #ddd;

			.label {
				width: 90px;
				text-align: right;
			}

			.spread-code {}
			
			.qrcode{
				display: flex;
				width: 200px;
				margin-left: 150px;
				margin-top: 10px;
			}
			
			.share-config{
				display: flex;
				
				.preview-wraper{
					flex: 0 0 auto;
					display: flex;
					flex-direction: column;
					width: 280px;
					
					.image{
						height: 388px;
						background-color: #F5f5f5;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					
					.bottom{
						position: relative;
						z-index: 1;
						height: 80px;
						display: flex;
						
						.left{
							flex: 1 1 auto;
							overflow: hidden;
							
							.name{
								font-size: 15px;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								padding: 15px 10px 10px;
								margin-right: 10px;
							}
							
							.desc{
								display: flex;
								align-items: center;
								font-size: 12px;
								overflow: hidden;
								margin: 0 10px;
								
								img{
									flex: 0 0 auto;
									width: 16px;
									height: 16px;
									border-radius: 100%;
									margin-right: 5px;
								}
								.text{
									flex: 1 1 auto;
									white-space: nowrap;
									overflow: hidden;
									line-height: 1;
								}
							}
						}
						.right{
							flex: 0 0 auto;
							margin-right: 10px;
							
							img{
								width: 68px;
								height: 68px;
								border-radius: 100%;
								overflow: hidden;
								margin: -20px auto 0;
							}
							.text{
								font-size: 10px;
								color: #999;
								margin-top: 5px;
								white-space: nowrap;
							}
						}
					}
				}
				
				.share-edit{
					flex: 1 1 auto;
					margin-left: 20px;
					margin-right: 10px;
				}
			}
			
			.mini-share-card{
				display: flex;
				
				.card-preview{
					flex: 0 0 auto;
					position: relative;
					
					.bg{
						display: block;
						width: 240px;
					}
					.card{
						position: absolute;
						top: 88px;
						right: 36px;
						width: 151px;
						
						display: flex;
						flex-direction: column;
						
						.top{
							flex: 0 0 auto;
							width: 100%;
						}
						.bottom{
							flex: 0 0 auto;
							width: 100%;
						}
						.center{
							flex: 1 1 auto;
							min-height: 138px;
							background: url(https://cdn.dkycn.cn/images/melyshop/mini-share-card-2.png) 0  repeat-y;
							background-size: 100% 100%;
							overflow: hidden;
							
							display: flex;
							flex-direction: column;
							
							.share-text{
								flex: 0 0 auto;
								line-height: 16px;
								min-height: 16px;
								max-height: 32px;
								font-size: 12px;
								margin: 3px 8px;
								
								display: -webkit-box; 
								-webkit-box-orient:vertical; 
								-webkit-line-clamp: 2; 
								overflow: hidden;
							}
							.share-image{
								display: block;
								width: 132px;
								height: 105px;
								margin: 3px 8px 8px;
							}
						}
					}
				}
			}
		}
	}
  .share-image-wraper{
		display: flex;
		align-items: center;
		
		.right{
			width: 170px;
			height: 120px;
			margin-left: 10px;
			
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			.right-tip{
				font-size: 12px;
				color: #999;
				line-height: 20px;
			}
			.right-clear{
				font-size: 14px;
				color: #1890ff;
				cursor: pointer;
			}
		}
	}
	
	.share-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		
	}
	
	.share-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	
	.share-uploader {
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
		border: 1px dashed #ddd;
		overflow: hidden;
	}
	.avatar-uploader-icon{
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}
	
	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}
</style>