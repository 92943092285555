<template>
  <div>
    <setting>
      <el-card style="width:500px">
        <template slot="header">页面标题</template>
        <div class="flex flex-align-center">
          <div class="font-label">页面标题</div>
          <el-input v-model="values.title" placeholder="若为空，默认为：推手招募" style="width:350px" maxlength="20"></el-input>
        </div>
      </el-card>
    </setting>
  </div>
</template>

<script>
import setting from './setting.vue'
export default {
  components:{
    setting
  },
  props: {
    config:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data () {
    return {
      values:{}
    }
  },
  watch: {
    config(val){
      // console.log(val)
      this.values = val
    }
  }
}
</script>

<style lang="less" scoped>
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.font-label{
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
  white-space: nowrap;
  flex:0 0;
  margin-right: 10px;
  font-weight: 700;
}
</style>