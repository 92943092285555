import request from '@/utils/request'

/*******商城装修************/
export const editSelectProduct = (data) => request('/pc/mall-shop-decorate/edit-select-product', data);
//自定义页面列表
export const mallShopDecorateList = (data) => request('/pc/mall-shop-decorate/index', data);
//通过分组选择商品
export const editSelectProductByGroup = (data) => request('/pc/mall-shop-decorate/edit-select-product-by-group', data);
//选择优惠券
export const editSelectCoupon = (data) => request('/pc/mall-shop-decorate/edit-select-coupon', data);
//选择积分兑换商品
export const editSelectPointExchange = (data) => request('/pc/mall-shop-decorate/edit-select-point-exchange', data);
//保存
export const editSave = (data) => request('/pc/mall-shop-decorate/edit-save', data);
//初始化
export const editInit = (data) => request('/pc/mall-shop-decorate/edit-init', data);
//页面列表
export const mallPageList = (data) => request('/pc/mall-shop-decorate/index', data);
//设为首页
export const setHome = (data) => request('/pc/mall-shop-decorate/set-home', data);
//定时设为首页
export const appointmentSetHome = (data) => request('/pc/mall-shop-decorate/appointment-set-home', data);

//删除页面
export const decorateDelete = (data) => request('/pc/mall-shop-decorate/delete', data);
//选择限时折扣活动
export const editSelectDiscount = (data) => request('/pc/mall-shop-decorate/edit-select-discount', data);
//限时折扣商品信息
export const editSelectDiscountProduct = (data) => request('/pc/mall-shop-decorate/edit-select-discount-product', data);
//大转盘活动列表
export const editSelectWheelSurf = (data) => request('/pc/mall-shop-decorate/edit-select-wheel-surf', data);
//社群专享商品列表
export const editSelectCommunityGroup = (data) => request('/pc/mall-shop-decorate/edit-select-community-group', data);
//页面管理编辑选择拼团活动
export const editSelectSpellGroup = (data) => request('/pc/mall-shop-decorate/edit-select-spell-group', data);
//获取直播分组列表
export const editSelectLiveRoomByGroup = (data) => request('/pc/mall-shop-decorate/edit-select-live-room-by-group', data);
//优惠券列表
// export const editSelectCoupon = (data) => request('/pc/mall-shop-decorate/edit-select-coupon', data);
//选择助力领取活动
export const editSelectHelpReceive = (data) => request('/pc/mall-shop-decorate/edit-select-help-receive', data);
//助力领取商品信息
export const editSelectHelpReceiveProduct = (data) => request('/pc/mall-shop-decorate/edit-select-help-receive-product', data);
//助力领取商品列表
export const editSelectAllHelpReceiveProduct = (data) => request('/pc/mall-shop-decorate/edit-select-all-help-receive-product', data);
//获取视频号id 
export const videoliveapplyinfo = (data) => request('/pc/videolive/videoliveapplyinfo', data);
//保存分享海报 
export const setSharePosterImg = (data) => request('/pc/mall-shop-decorate/setSharePosterImg', data);



//推手招募页分享信息保存
export const malldriverRecruitmentPageShareInfoSave = (data) => request('/pc/mall/driverRecruitmentPageShareInfoSave', data);

