<template>
  <div>
    <setting>
      <el-card style="width:500px">
        <template slot="header">邀请背景图</template>
        <div class="flex">
          <div class="font-label" style="line-height:1.5">背景图设置</div>
          <div>
            <img :src="imgurl+values.bgImage" class="upload-img" alt="">
            <el-upload :action="imgapi" :on-success="handleAvatarSuccess" accept="image/*" :show-file-list="false"
              :before-upload="beforeAvatarUpload" list-type="text">
              <el-button type="text">更换背景图</el-button>
            </el-upload>
          </div>
          <div style="margin-left:20px">
            <div class="grayfont-12" style="margin:0">背景图上传建议:</div>
            <div class="grayfont-12" style="margin:0">（1）建议图片宽750px，高400px</div>
            <div class="grayfont-12" style="margin:0">（2）图片大小不超过1M</div>
          </div>
        </div>
        <div class="flex" style="margin-top:20px">
          <div class="font-label">主标题</div>
          <div>
            <el-input v-model="values.mainTitle" placeholder="" style="width:350px" maxlength="8"></el-input>
            <div class="grayfont-12">若不需要显示主标题，删除输入框里的内容即可</div>
          </div>
        </div>
        <div class="flex" style="margin-top:20px">
          <div class="font-label">副标题</div>
          <div>
            <el-input v-model="values.subtitle" placeholder="" style="width:350px" maxlength="16"></el-input>
            <div class="grayfont-12">若不需要显示副标题，删除输入框里的内容即可</div>
          </div>
        </div>
      </el-card>
    </setting>
  </div>
</template>

<script>
import setting from './setting.vue'
import config from '@/config'
export default {
  components:{
    setting
  },
  props: {
    config:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    config(val){
      // console.log(val)
      this.values = val
    }
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      imgapi:config.UPLOAD_IMG,
      values:{}
    }
  },
  methods:{
    handleAvatarSuccess(file){
      // console.log(file)
      this.values.bgImage = file[0]
      // console.log(this.values)
    },
    beforeAvatarUpload(file){
      let size = file.size/1024/1024 < 1
      // console.log(file.type)
      let list = ['image/jpeg','image/jpg','image/png','image/gif']
      if(list.indexOf(file.type)==-1){
        this.$message.error('仅支持上传jpg，png，gif格式图片')
        return false
      }
      if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.font-label{
  font-size: 14px;
  color: #606266;
  line-height: 36px;
  white-space: nowrap;
  flex:0 0 70px;
  margin-right: 10px;
  text-align: right;
  font-weight: 700;
}
.grayfont-12{
  color: #999999;
  line-height: 1.5;
  font-size: 12px;
  margin-top:5px
}
.upload-img{
  width: 120px;
  height: 65px;
}
</style>