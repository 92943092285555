<template>
  <div>
    <setting>
      <el-card style="width:600px">
        <template slot="header">邀请内容</template>
        <div class="max-900">
          <div class="flex">
            <div class="font-label">标题背景色</div>
            <div class="flex flex-align-center">
              <div style="margin:0px 5px 0px 0px;font-size: 14px;">1.浅色</div>
              <el-color-picker v-model="values.lightColor"></el-color-picker>
              <div style="margin:0px 5px 0px 35px;font-size: 14px;">2.深色</div>
              <el-color-picker v-model="values.darkColor"></el-color-picker>
            </div>
          </div>
          <div v-for="(v,i) in values.list" :key="i" class="flex" style="margin-top:20px">
            <div class="font-label">内容{{i+1}}</div>
            <div class="garybox">
              <div class="flex">
                <div class="font-label" style="flex:0 0 30px">标题</div>
                <el-input v-model="v.title" placeholder="" maxlength="15"></el-input>
              </div>
              <div class="flex" style="margin-top:20px">
                <div class="font-label" style="flex:0 0 30px">内容</div>
                <el-input resize="none" type="textarea" v-model="v.content" placeholder="" maxlength="500" :rows="6" show-word-limit></el-input>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </setting>
  </div>
</template>

<script>
import setting from './setting.vue'
export default {
  components:{
    setting
  },
  props: {
    config:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    config(val){
      // console.log(val)
      this.values = val
    }
  },
  data () {
    return {
      values:{}
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
*{
  // font-size: 14px;
  color: #606266;
}
.max-900{
  max-height: 800px;
  overflow-y: auto;
}
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.font-label{
  font-size: 14px;
  color: #606266;
  line-height: 36px;
  white-space: nowrap;
  flex:0 0 70px;
  margin-right: 10px;
  text-align: right;
  font-weight: 700;
}
.grayfont-12{
  color: #999999;
  line-height: 1.5;
  font-size: 12px;
  margin-top:5px
}
.garybox{
  background: rgb(251,251,251);
  padding:16px;
  flex:1
}
</style>