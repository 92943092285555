<template>
  <div class="modepage flex flex--align-start" id="page" ref="page" :style="{'min-height':minheight}">
    <div class="main">
      <div class="header" :class="{outline:editnum==1}" @click="showedit(1)">
        <div class="header-cursor">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-header.png" alt="">
          <div class="header-title">{{config.title}}</div>
        </div>
        <pagetitle :config="config" v-show="editnum==1" class="class-setpagetitle"></pagetitle>
      </div>
      <div class="welcome" :class="{outline:editnum==2}" @click="showedit(2)">
        <div class="welcome-cursor">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-user-header.png" style="width:32px" alt="">
          <div class="wel-font">
            <span style="margin-right:5px">XXX</span>
            <span>{{config.welcome}}</span>
          </div>
        </div>
        <welcome :config="config" v-show="editnum==2" class="class-setwelcome"></welcome>
      </div>
      <div class="banner" :class="{outline:editnum==3}" @click="showedit(3)">
        <div class="banner-cursor">
          <img :src="imgurl+config.bgImage" alt="">
          <div class="bannerbox">
            <div class="title">{{config.mainTitle}}</div>
            <div class="subtitle">{{config.subtitle}}</div>
          </div>
        </div>
        <bg :config="config" v-show="editnum==3" class="class-setbg"></bg>
      </div>
      <div class="howtobe" ref="howtobe" :class="{outline:editnum==4}" @click="showedit(4)">
        <div class="howtobe-cursor">
          <div v-for="(v,i) in config.list" :key="i">
            <div class="howtobox" v-if="i==0">
              <div class="contentbox">
                <div class="howto-button" :style="{background: `linear-gradient(90deg, ${config.lightColor} 0%, ${config.darkColor} 100%)`}">{{v.title}}</div>
                <div class="center-font" v-html="v.content"></div>
                <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-bottom2.png" style="width:345px" alt="">
              </div>
            </div>
            <div class="howtobox" v-if="i==1" style="margin-top:15px">
              <div class="contentbox">
                <div class="howto-button" :style="{background: `linear-gradient(90deg, ${config.lightColor} 0%, ${config.darkColor} 100%)`}">{{v.title}}</div>
                <div class="center-font" v-html="v.content"></div>
                <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-bottom2.png" style="width:345px" alt="">
              </div>
            </div>
            <div class="howtobox" v-if="i==2" style="margin-top:15px">
              <div class="contentbox">
                <div class="howto-button" :style="{background: `linear-gradient(90deg, ${config.lightColor} 0%, ${config.darkColor} 100%)`}">{{v.title}}</div>
                <div class="center-font" v-html="v.content"></div>
                <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-bottom2.png" style="width:345px" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="class-setcontent">
          <pagecontent ref="pagecontent" :config="config" v-show="editnum==4"></pagecontent>
        </div>
      </div>
    </div>
    <div class="right-set">

    </div>
  </div>
</template>

<script>
import pagetitle from './pagetitle.vue'//页面标题
import welcome from './welcome.vue'//邀请语
import bg from './bg.vue'//邀请背景图
import pagecontent from './content.vue'//邀请背景图
import config from '@/config'
export default {
  components:{
    pagetitle,
    welcome,
    bg,
    pagecontent
  },
  props: {
    config:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  computed: {
    minheight(){
      return this.editnum==4?'1400px':'600px'
    }
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      editnum:0,
    }
  },
  methods:{
    showedit(num){
      this.editnum = num
    },
  }
}
</script>

<style lang="less">
  .flex{
    display: flex;
  }
  .flex--align-start{
    align-items: flex-start;
  }
  .modepage{
    width: 100%;
    overflow-y: auto;
    padding-left: 10px;
    // border:1px solid black
  }
  .main{
    width: 375px;
    background: rgb(245,245,245);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    flex-shrink: 0;
    img{
      width: 375px;
    }
    .header{
      position: relative;
      top: 0;
      left: 0;
      .header-cursor{
        cursor: pointer;
        width: 375px;
        height: 104px;
        display: flex;
        justify-content: center;
        .header-title{
          position: absolute;
          width: 100%;
          // border:1px solid black;
          bottom: 20px;
          text-align: center;
          max-width: 200px;
          overflow     : hidden;
          text-overflow: ellipsis;
          white-space  : nowrap;
        }
      }
      .class-setpagetitle{
        position: absolute;
        top: 0;
        left: 400px;
      }
    }
    .welcome{
      position: relative;
      padding: 8px 20px;
      background: white;
      // margin-top: -5px;
      // margin: 2px 0px;
      .welcome-cursor{
        cursor: pointer;
        display: flex;
        align-items: center;
        .wel-font{
          color: #333333;
          font-size: 14px;
          line-height: 1.5;
          margin-left:20px
        }
      }
      .class-setwelcome{
        position: absolute;
        top: 0;
        left: 400px;
      }
    }
    .banner{
      width: 375px;
      position: relative;
      top: 0;
      left: 0;
      z-index: 50;
      min-height: 200px;
      img{
        vertical-align: bottom;
      }
      .banner-cursor{
        cursor: pointer;
        .bannerbox{
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          // border:1px solid black;
          .title{
            font-size: 40px;
            color: #F4E0C6;
            text-align: center;
            margin-top: 38px;
            line-height: 1.5;
          }
          .subtitle{
            font-size: 16px;
            color: #FFFFFF;
            text-align: center;
            margin-top: 8px;
            line-height: 1.5;
          }
        }
      }
      .class-setbg{
        position: absolute;
        top: 0;
        left: 400px;
      }
    }
    .howtobe{
      width: 375px;
      z-index: 100;
      position: relative;
      top: -29px;
      left: 0;
      .howtobe-cursor{
        cursor: pointer;
        .howtobox{
          .contentbox{
            width: 345px;
            margin: 0px auto;
            background: white;
            // height: 168px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
            position: relative;
            .howto-button{
              // height: 38px;
              // width: 145px;
              padding: 8px 20px;
              border-radius: 26px;
              margin-top: 15px;
              // background: linear-gradient(90deg, #C0391F 0%, #8C1E09 100%);
              font-size: 16px;
              line-height: 1.5;
              font-weight: bold;
              color: #FFFFFF;
              text-align: center;
            }
            .center-font{
              font-size: 14px;
              color: #606266;
              line-height: 1.5;
              margin-top: 15px;
              width: 300px;
              white-space: pre-wrap;
              // border:1px solid black;
              margin-bottom: 50px;
              word-break: break-all;
            }
            img{
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
      .class-setcontent{
        position: absolute;
        top: 0;
        left: 400px;
        // border: 1px solid black;
      }
    }
    .outline{
      outline: 2px solid #409eff;
      margin: 2px 0px;
        clear:both;
        display: block;
    }
  }
  .right-set{
    width: 1000px;
    // background: red;
    height: 500px;
    flex-shrink: 0;
  }
</style>